import React from "react";
import { graphql } from "gatsby";
import GatsbyImage from "gatsby-image";
import PageWrapper from "components/PageWrapper";
import RichText from "components/RichText";
import HollowCircularButton from "components/HollowCircularButton";
import log from "utils/log";
import * as coreTheme from "@bit/cpinnix.verious.theme";
import * as siteTheme from "theme";
import * as heroClasses from "./hero.module.css";

export const query = graphql`
  query {
    descriptionImage: contentfulAsset(
      contentful_id: { eq: "4MO4JDc5UBW9bUV9oR5DaR" }
    ) {
      id
      fixed {
        src
      }
    }
    heroImage: contentfulAsset(
      contentful_id: { eq: "3zfevmbHD7RflZLzgfZvyY" }
    ) {
      id
      fluid {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    partners: allContentfulPartner {
      edges {
        node {
          id
          description {
            raw
          }
          cta
          name
          logo {
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

function present(data) {
  const seo = {
    title: "Home",
    image: data?.descriptionImage?.fixed?.src,
    description:
      "Nana's Haven in St. Petersburg, FL offers support and services for neglected and abused animals. Learn more about us today.",
  };

  log("[PAGE] data", data);

  const presentation = {
    seo,
    heroImage: data?.heroImage?.fluid,
    partners: data?.partners?.edges.map((edge) => edge.node),
  };

  log("[PAGE] presentation", presentation);

  return presentation;
}

export default function Page({ data }) {
  const { partners, heroImage, seo } = present(data);

  return (
    <PageWrapper seo={seo}>
      {/* Hero ---> */}
      <div className="container mx-auto px-8 md:px-16">
        <div className={heroClasses.heroLayout}>
          <div className={heroClasses.heroText}>
            <div className={heroClasses.heroTextLayout}>
              <h1 className={heroClasses.heroTitle}>Partners</h1>
            </div>
          </div>
          <div className={heroClasses.heroImage}>
            <GatsbyImage fluid={heroImage} />
          </div>
        </div>
      </div>
      {/* <---- Hero */}

      {partners.map((partner) => (
        <div className="py-32 bg-gray-200 even:bg-gray-100">
          <div className="container mx-auto px-8 md:px-16">
            <div className="md:grid md:gap-8 md:grid-cols-12">
              <div className="md:col-span-4 mb-16">
                <GatsbyImage fluid={partner.logo[0].fluid} />
              </div>
              <div className="md:col-start-6 md:col-span-4">
                <h2
                  className="text-4xl font-bold text-custom-slate mb-4"
                  style={{ fontFamily: "Source Serif Pro" }}
                >
                  {partner.name}
                </h2>
                <div>
                  <RichText
                    json={partner.description}
                    theme={{
                      title: {
                        color: siteTheme.heading.primary,
                        fontFamily: siteTheme.font.primary,
                      },
                    }}
                  />
                </div>
                <a href={partner.cta} target="_blank" rel="noreferrer">
                  <HollowCircularButton
                    theme={{
                      borderColor: coreTheme.gray[500],
                      color: coreTheme.gray[600],
                    }}
                  >
                    Learn More
                  </HollowCircularButton>
                </a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </PageWrapper>
  );
}
